<template>
    <div class="compound-blob">
        <img class="compound-blob__blob" :src="blob">

        <slot />
    </div>
</template>

<script>
const blob = require('@/assets/textures/compound-blob.svg');

export default {
    name: 'CompoundBlob',
    data() {
        return {
            blob,
        };
    },
};
</script>

<style lang="stylus">
.compound-blob {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 0;
    &__blob {
        position: absolute;
        right: -16%;
        top: 0;
        width: 135%;
        z-index: -1;
    }
}
</style>
