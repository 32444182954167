var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PageWrapper',[_c('NebulaGridRow',[_c('NebulaGridBlock',{attrs:{"columns":6,"offset":3,"mdSize":6,"mdOffset":1,"smSize":4}},[_c('Stepper',{staticClass:"profile-setup__stepper",attrs:{"parentActive":_vm.active,"steps":_vm.steps,"completed":_vm.stepsCompleted,"showIndex":false},on:{"update-current-step":_vm.updateCurrent}})],1)],1),_c('NebulaGridRow',[_c('NebulaGridBlock',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive('personal')),expression:"isActive('personal')"}],attrs:{"columns":6,"offset":3,"mdSize":6,"mdOffset":1,"smSize":4}},[_c('Phase',{staticClass:"employee-profile-setup__phase",attrs:{"data":{
                    header: _vm.$t('setup-title', { ns: this.view }),
                    description: _vm.$t('instruction', { ns: this.view }),
                    nextText: _vm.$t('save-continue'),
                },"avatarProps":{
                    image: _vm.avatarImage
                }}},[_c('EmployeeProfileStep1',{on:{"next-step":function($event){return _vm.updateCurrent()}}})],1)],1)],1),_c('NebulaGridRow',[_c('NebulaGridBlock',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive('availability')),expression:"isActive('availability')"}],attrs:{"columns":8,"offset":2,"mdSize":8}},[_c('Phase',{staticClass:"employee-profile-setup__phase",attrs:{"data":{
                    header: _vm.$t('set-availability', { ns: 'employeeprofile' }),
                    description: _vm.$t('when-meet-blob', { ns: 'employeeprofile' }),
                    nextText: _vm.$t('save-continue'),
                }}},[_c('EmployeeProfileStep2',{on:{"next-step":function($event){return _vm.updateCurrent()},"previous-step":function($event){return _vm.goBack()}}})],1)],1)],1),_c('NebulaGridRow',[_c('NebulaGridBlock',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive('expertise')),expression:"isActive('expertise')"}],attrs:{"columns":6,"offset":3,"mdSize":6,"mdOffset":1,"smSize":4}},[_c('Phase',{staticClass:"employee-profile-setup__phase",attrs:{"data":{
                    header: _vm.$t('share-expertise', { ns: this.view }),
                    description: _vm.$t('get-to-know', { ns: this.view }),
                }}},[_c('ConnectorProfileStepExpertise',{attrs:{"nextText":_vm.flags['calendar-removed'] ? _vm.$t('Save') : _vm.$t('save-continue')},on:{"next-step":function($event){return _vm.handleExpertiseDone()},"previous-step":function($event){return _vm.goBack()}}})],1)],1)],1),_c('NebulaPageWrapper',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive('confirmation')),expression:"isActive('confirmation')"}]},[_c('NebulaGridRow',[_c('NebulaGridBlock',{attrs:{"columns":6,"offset":3,"mdSize":6,"mdOffset":1,"smSize":4}},[_c('Phase',{staticClass:"employee-profile-setup__phase",attrs:{"data":{
                        header: _vm.$t('profile-confirmation-header', { ns: 'employeeprofile' }),
                        description: _vm.$t('profile-review', { ns: 'employeeprofile' }),
                    }}},[_c('ProfileSetupActions',{attrs:{"nextText":_vm.$t('Save'),"center":"","analyticsAttributes":{
                            next: {
                                'data-click-type': 'Profile Setup Workflow: Connector',
                                'data-click-name': 'Profile Confirmation: Save',
                            },
                        }},on:{"click-next":_vm.saveProfile},scopedSlots:_vm._u([{key:"nextButtons",fn:function(){return [_c('NebulaButton',{attrs:{"type":"ghost","text":_vm.$t('Edit Profile'),"icon-right":"edit","data-click-type":"Profile Setup Workflow: Connector","data-click-name":"Profile Confirmation: Edit Profile"},on:{"click":function($event){return _vm.goBack(0)}}})]},proxy:true}])})],1)],1)],1),_c('NebulaGridRow',{staticClass:"employee-profile-setup__profile",attrs:{"gutter":""}},[_c('NebulaGridBlock',{staticClass:"employee-profile-setup__profile-panel",attrs:{"columns":6,"mdSize":8}},[_c('EmployeeProfile',{attrs:{"preview":"","data":_vm.employeeData}})],1),_c('NebulaGridBlock',{staticClass:"employee-profile-setup__availability-panel",attrs:{"columns":6,"mdSize":8}},[_c('GeneralAvailability',{attrs:{"availability":_vm.timeSlots,"showIcons":false},on:{"select-timeslot":_vm.addAvailabilitySlot,"deselect-timeslot":_vm.removeAvailabilitySlot,"user-updated":_vm.markUserUpdated}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }