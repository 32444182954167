<template>
    <GrayBoxLoader v-if="loading" />
    <div v-else class="employee-profile">
        <ProfileBanner class="employee-profile__profile-banner" />
        <div class="employee-profile__content">
            <ProfileAvatar
                class="employee-profile__user-avatar"
                :image="data.avatarUrl"
                :border="true"
                :name="data.firstName + ' ' + data.lastName"
                size="xl"
            />
            <NebulaButton
                v-if="!preview && selfView"
                class="employee-profile__edit-button"
                icon-left="edit"
                link="/profile-setup"
                text="Edit Profile"
                type="flat"
            />
            <div class="employee-profile__user-information">
                <h2 class="employee-profile__name">
                    {{ displayName }} {{ data.lastName }}
                    <span
                        v-if="data.pronouns"
                        class="employee-profile__pronouns"
                    >
                        ({{ $t(data.pronouns) }})
                    </span>
                </h2>
                <p class="employee-profile__title">
                    {{ data.title }}
                </p>
                <p class="employee-profile__location">
                    {{ data.city }}, {{ data.state }}
                </p>
            </div>
            <div class="employee-profile__company-list">
                <div
                    v-for="(organization, idx) in organizations"
                    :key="idx"
                    class="employee-profile__company"
                >
                    <ProfileAvatar
                        class="employee-profile__company-avatar"
                        :image="organization.avatarUrl"
                        :border="true"
                        icon="buildings"
                        :name="organization.name"
                        size="xs"
                    />
                    <p v-if="preview" class="employee-profile__company-link">
                        {{ organization.name }}
                    </p>
                    <OrgName v-else :organization="organization" />
                </div>
            </div>
            <NebulaTitleTooltip
                v-if="flags['pause-direct-requests'] && directRequestsDisabled"
                position="block-start"
                :text="$t('direct-request-paused-tooltip', { ns: 'employeeprofile' })"
                class="employee-profile__direct-request-tooltip"
            >
                <NebulaButton
                    class="employee-profile__direct-request-button--nested"
                    text="Send Direct Request"
                    type="ghost"
                    routerLink
                    :link="`/direct-request/${data.employeeId}`"
                    data-click-type="View Connector Profile: Educator"
                    data-click-name="Send Direct Request"
                />
            </NebulaTitleTooltip>
            <NebulaButton
                v-else-if="showDirectRequest"
                class="employee-profile__direct-request-button"
                text="Send Direct Request"
                type="ghost"
                routerLink
                :link="`/direct-request/${data.employeeId}`"
                data-click-type="View Connector Profile: Educator"
                data-click-name="Send Direct Request"
            />
            <section v-if="data.description" class="employee-profile__section employee-profile__section--bio">
                <h3 class="employee-section__title">About Me</h3>
                <p class="employee-section__text">
                    <!-- TODO: Add "See More" and "See Less" functionality -->
                    {{ data.description }}
                </p>
            </section>
            <section v-if="hasTags" class="employee-profile__section employee-profile__section--expertise">
                <h3 class="employee-section__title">Areas of Expertise</h3>
                <ul class="employee-section__list">
                    <li
                        v-for="tag in data.tags"
                        :key="tag"
                        class="employee-section__list-item"
                    >
                        <NebulaBadge
                            :text="getTagText(tag)"
                            :icon="getSubjectIcon(tag)"
                            :customStyles="{
                                badgeBackgroundColor: 'var(--nebula-color-platform-neutral-200)',
                                badgeColor: 'var(--nebula-color-platform-neutral-900)',
                            }"
                        />
                    </li>
                </ul>
            </section>
            <section class="employee-profile__section employee-profile__section--associations" v-if="data.associations">
                <h3 class="employee-section__title">Professional Associations</h3>
                <ul class="employee-section__list">
                    <li
                        v-for="association in data.associations"
                        :key="association"
                        class="employee-section__list-item"
                    >
                        <a :href="association.link" class="employee-section__link">
                            {{ association.text }}
                        </a>
                    </li>
                </ul>
            </section>
            <section v-if="data.languages" class="employee-profile__section employee-profile__section--languages">
                <h3 class="employee-section__title">Languages</h3>
                <ul class="employee-section__list">
                    <li
                        v-for="language in data.languages"
                        :key="language"
                        class="employee-section__list-item"
                    >
                        <NebulaBadge
                            class="employee-profile__language-badge"
                            :text="language"
                            type="outline"
                        />
                    </li>
                </ul>
            </section>
        </div>
    </div>
</template>

<script>
import { NebulaBadge, NebulaButton, NebulaTitleTooltip } from '@discoveryedu/nebula-components';
import ProfileAvatar from '@/components/shared/ProfileAvatar.vue';
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';
import ProfileBanner from '@/components/shared/Profile/ProfileBanner.vue';
import OrgName from '@/components/shared/tokens/OrgName.vue';

import subjectIconMap from '@/data/subjectIconMap';
import { tagOptions } from '@/data/formData';
import { roles } from '@/constants';

export default {
    name: 'EmployeeProfile',
    components: {
        NebulaBadge,
        NebulaButton,
        NebulaTitleTooltip,
        ProfileAvatar,
        GrayBoxLoader,
        ProfileBanner,
        OrgName,
    },
    computed: {
        appMode() {
            return this.$store.state.app.mode;
        },
        hasTags() {
            try {
                Object.keys(this.data.tags);
                return this.data.tags.length > 0;
            } catch {
                return false;
            }
        },
        displayName() {
            return this.data.preferredName || this.data.firstName;
        },
        tagOptions,
        organizations() {
            return this.selfView ? this.$store.state.user.organizations : this.data.organizations;
        },
        showDirectRequest() {
            return this.appMode === roles.EDUCATOR_ROLE;
        },
        directRequestsDisabled() {
            if (!this.showDirectRequest) {
                return false;
            }
            return !this.data.direct_requests_active;
        },
        // feature flags
        flags() {
            return this.$store.getters.flags;
        },
    },
    data() {
        return {
            subjectIconMap,
            profileBannerConfig: {
                alt: '',
                class: 'employee-profile__gradient-image',
                height: '121px',
                src: '@/assets/employee-profile-gradient-background.jpg',
                width: '100%',
            },
        };
    },
    props: {
        data: {
            type: Object,
        },
        preview: {
            type: Boolean,
        },
        selfView: {
            type: Boolean,
            default: false,
        },
        loading: Boolean,
    },
    methods: {
        getSubjectIcon(subject) {
            const subjectString = subject.replace(/[- ]/g, '').trim().toLowerCase();

            return this.subjectIconMap[subjectString];
        },
        getTagText(value) {
            const match = this.tagOptions.find((each) => each.value === value);
            return match.text;
        },
        getOrgId(org) {
            return org.organizationId || org.organization_id;
        },
    },
};
</script>

<style lang='stylus'>
.employee-profile {
    position: relative;

    &__content {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: $nebula-space-2x;
        padding: 0 $nebula-space-3x $nebula-space-3x;
    }

    &__direct-request-button {
        --nebula-button-background: $nebula-color-white;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            position: absolute;
            top: $nebula-space-2x;
            right: $nebula-space-2x;
        }

        &--nested {
            --nebula-button-background: $nebula-color-white;
        }
    }

    &__direct-request-tooltip {
        .nebula-button--ghost {
            --nebula-button-background: $nebula-color-platform-interface-300;
            --nebula-button-background-hover: $nebula-color-platform-interface-300;
            --nebula-button-text-color: $nebula-color-platform-interface-700;
            --nebula-button-text-color-hover: $nebula-color-platform-interface-700;
            --nebula-button-border-color: $nebula-color-platform-interface-300;
            --nebula-button-border-color-hover: $nebula-color-platform-interface-300;
            pointer-events: none;
        }
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            position: absolute;
            top: $nebula-space-2x;
            right: $nebula-space-2x;
        }

        .nebula-title-tooltip__text {
            max-width: 300px;
            text-align: center;
        }
    }

    &__profile-banner {
        border-top-right-radius: $nebula-border-radius-small;
        border-top-left-radius: $nebula-border-radius-small;
        overflow: hidden;
    }

    &__language-badge {
        --nebula-badge-text-transform: capitalize;
    }

    &__user-avatar {
        --careers-avatar-border-color: var(--nebula-color-white);

        margin-block-start: calc(calc(136px / 2) * -1); // This element is 136px tall with the xl size and a 3px border.
    }

    &__name {
        nebula-text-responsive-h6();

        text-align: center;
        margin: 0;
    }

    &__pronouns {
        nebula-text-caption();

        color: $nebula-color-platform-neutral-700;
    }

    &__title {
        nebula-text-body-2();
        nebula-text-semibold();

        color: $nebula-color-platform-neutral-900;
        text-align: center;
        margin: 0;
    }

    &__location {
        nebula-text-caption();

        color: $nebula-color-platform-neutral-700;
        text-align: center;
        margin: 0;
    }

    &__company-list {
        display: flex;
        flex-direction: column;
    }

    &__company {
        align-items: center;
        display: flex;
        gap: $nebula-space-1x;
        justify-content: center;
    }

    &__company-avatar {
        border: 1px solid $nebula-color-platform-neutral-200;
    }

    &__company-link {
        nebula-text-caption();

        color: $nebula-color-platform-neutral-700;
    }

    &__section {
        display: flex;
        flex-direction: column;
        gap: $nebula-space-1x;
        width: 100%;
    }
}

.employee-section__title {
    nebula-text-body-1();

    font-weight: bold;
    margin: 0;
}

.employee-section__text {
    nebula-text-body-2();

    margin: 0;
}

.employee-section__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $nebula-space-1x;
    margin: 0;
    padding: 0;

    .employee-profile__section--associations & {
        flex-direction: column;
    }
}

.employee-section__list-item {
    list-style: none;
}

.employee-section__link {
    color: $nebula-color-platform-neutral-900;
}
</style>
