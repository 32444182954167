<template>
    <div>
        <p v-if="!flags['bio-helper']" class="profile-setup__bold-label">
            {{ $t('tell-us-expertise', { ns: 'employeeprofile' }) }}
        </p>
        <ProfileFormField
            v-if="!flags['bio-helper']"
            name="description"
            inputType="textarea"
            class="profile-setup__description"
            :inputGroupData="formData.description"
            :validation="validateInput"
        />
        <p class="profile-setup__bold-label">
            {{ $t('choose-topics', { ns: 'employeeprofile' }) }}
        </p>
        <div class="profile-setup__tag-box">
            <NebulaTag
                v-for="(tag, idx) in formData.tags.value"
                class="profile-setup__tag"
                :key="idx"
                :text="getTagText(tag)"
                @click="removeTag(tag)"
            />
            <NebulaButton
                v-show="!tagInput"
                :aria-label="$t('add-tag', { ns: 'employeeprofile' })"
                :text="$t('add-tag', { ns: 'employeeprofile' })"
                shape="pill"
                size="s"
                icon-left="plus"
                type="flat"
                :aria-disabled="formData.tags.value.length >= 6"
                @click="activateInput"
            />
            <div v-show="tagInput" class="profile-setup__tag-input-group" @keydown.enter="handleTagKeydown">
                <NebulaInput
                    ref="tagInput"
                    class="profile-setup__tag-input"
                    v-model="customTag"
                    @input="filterMenu()"
                />
                <ul class="profile-setup__tag-menu" ref="tagMenu">
                    <li v-for="(option, idx) in tagOptions" :key="idx">
                        <button
                            class="profile-setup__tag-menu-item"
                            :class="{ 'profile-setup__tag-menu-item--disabled': !validateTag(option.value)}"
                            @click="selectOption(option.value)"
                        >
                            {{ option.text }}
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <InputLimitHelper>{{ formData.tags.value.length }}/6</InputLimitHelper>
        <p v-if="flags['bio-helper']" class="profile-setup__bold-label">
            {{ $t('tell-us-expertise', { ns: 'employeeprofile' }) }}
            <NebulaButton
                @click="toggleBioModal"
                class="profile-setup__inline-button"
                type="flat"
                size="s"
                :text="$t('view-bio', { ns: 'employeeprofile' })"
                data-click-type="Profile Setup Workflow: Connector"
                :data-click-name="$t('view-bio', { ns: 'employeeprofile' })"
            />
        </p>
        <ProfileFormField
            v-if="flags['bio-helper']"
            name="description"
            inputType="textarea"
            class="profile-setup__description profile-setup__description--tall"
            :inputGroupData="formData.description"
            :validation="validateInput"
        />
        <ProfileSetupActions
            @click-next="saveAndContinue"
            @click-back="goBack"
            :nextDisabled="saveProcessing"
            :nextText="saveProcessing ? 'Processing' : nextText"
            showBack
            :analyticsAttributes="{
                next: {
                    'data-click-type': 'Profile Setup Workflow: Connector',
                    'data-click-name': 'Bio & Tags: Save',
                },
                back: {
                    'data-click-type': 'Profile Setup Workflow: Connector',
                    'data-click-name': 'Bio & Tags: Back',
                },
            }"
        />
    </div>
</template>

<script>
import {
    NebulaButton,
    NebulaTag,
    NebulaInput,
} from '@discoveryedu/nebula-components';
import ProfileFormField from '@/components/shared/Profile/ProfileFormField.vue';
import ProfileSetupActions from '@/components/shared/Profile/ProfileSetupActions.vue';
import { tagOptions } from '@/data/formData';
import InputLimitHelper from '@/components/shared/forms/InputLimitHelper.vue';
import createPayload from '@/mixins/data/createPayload';

export default {
    name: 'ConnectorProfileStepExpertise',
    components: {
        NebulaButton,
        NebulaTag,
        NebulaInput,
        ProfileSetupActions,
        ProfileFormField,
        InputLimitHelper,
    },
    computed: {
        tagOptions,
        loading() {
            return this.$store.state.status.user.loading;
        },
        flags() {
            return this.$store.getters.flags;
        },
    },
    mixins: [createPayload],
    props: {
        nextText: String,
    },
    data() {
        return {
            formData: {
                description: {
                    value: '',
                    required: false,
                    error: null,
                    status: '',
                    maxLength: 750,
                    placeholder: this.$t('bio-placeholder', { ns: 'employeeprofile' }),
                },
                tags: {
                    value: [],
                    required: false,
                    error: null,
                    status: '',
                },
            },
            tags: [],
            tagInput: false,
            customTag: '',
            saveProcessing: false,
        };
    },
    methods: {
        async saveAndContinue() {
            this.saveProcessing = true;
            if (!this.saveButtonDisabled) {
                const payload = await this.createPayload();

                const storeable = {};
                Object.keys(this.formData).forEach((field) => {
                    storeable[field] = this.formData[field].value;
                });

                // store content if validation passes
                this.$store.dispatch('updateUserData', storeable);
                await this.$store.dispatch('saveEmployee', payload);
                this.$emit('next-step');
                this.saveProcessing = false;
            } else {
                this.showErrors = true;
                this.saveProcessing = false;
            }
        },
        goBack() {
            this.$emit('previous-step');
        },
        isValid(key) {
            // apply other validation here
            const { value, required } = this.formData[key];
            if (required) {
                return value.length > 0;
            }
            return true;
        },
        validateInput(key) {
            const passes = this.isValid(key);
            // 'status' is for the purpose of display state only
            // only update the status for an individual field if errors should be visible
            if (this.showErrors) {
                const errorString = 'error';

                this.formData[key].status = !passes && errorString;
            }

            this.formData[key].error = !passes;
        },
        async activateInput() {
            this.tagInput = true;
            await this.$nextTick();
            this.$refs.tagInput.$el.focus();
        },
        selectOption(option) {
            this.tagInput = false;
            // this.tags.push({ text: option });
            this.formData.tags.value.push(option);
            this.customTag = '';
            this.resetTagFilter();
        },
        validateTag(tag) {
            // TODO any additional validation here
            const match = this.formData.tags.value.find((each) => each === tag);
            return Boolean(!match);
        },
        removeTag(tag) {
            const match = this.formData.tags.value.findIndex((each) => each === tag);
            this.formData.tags.value.splice(match, 1);
            this.tagInput = false;
        },
        getTagText(value) {
            const selectedTag = this.tagOptions.find((each) => each.value === value);
            return selectedTag.text;
        },
        toggleBioModal() {
            this.$store.dispatch('toggleModal', {
                category: 'profile',
                type: 'bio',
                show: !this.$store.getters.profileBioModal.show,
            });
        },
        // TODO use if we add custom tags
        // handleTagKeydown(e) {
        //     if (e.target.matches('input')) {
        //         // bail if no input
        //         if (this.customTag.length < 1) {
        //             return;
        //         }

        //         const isValid = this.validateTag(this.customTag);
        //         if (!isValid) {
        //             // bail if custom tag already added
        //             return;
        //         }

        //         this.tags.push({ text: this.customTag });
        //         this.customTag = '';
        //     }
        // },
        getMenuItems() {
            return this.$refs.tagMenu.querySelectorAll('li');
        },
        filterMenu() {
            const menuItems = this.getMenuItems();
            const term = this.customTag.toLowerCase();
            menuItems.forEach((item) => {
                if (!item.innerText.toLowerCase().includes(term)) {
                    // eslint-disable-next-line no-param-reassign
                    item.style.display = 'none';
                } else {
                    // eslint-disable-next-line no-param-reassign
                    item.style.display = 'block';
                }
            });
        },
        resetTagFilter() {
            const menuItems = this.getMenuItems();
            // eslint-disable-next-line no-return-assign, no-param-reassign
            menuItems.forEach((item) => item.style.display = 'block');
        },
        populateFields() {
            const userData = this.$store.state.user.data;
            const fields = Object.keys(userData);

            // enum/select fields that need some standardization
            fields.forEach((each) => {
                if (this.formData[each]) {
                    const incomingValue = userData[each];
                    this.formData[each].value = incomingValue;
                }
            });
        },
    },
    watch: {
        loading(loading) {
            if (!loading) {
                this.populateFields();
            }
        },
    },
    mounted() {
        this.populateFields();
        if (this.flags['bio-helper']) {
            this.formData.description.maxLength = 1000;
        } else {
            this.formData.description.placeholder = 'Write a short bio...';
        }
    },
};
</script>

<style lang="stylus">
.profile-setup {
    &__bold-label {
        font-weight: 600;
    }
    &__description {
        margin-block-end: $nebula-space-5x;
        &--tall {
            min-height: 300px;
            textarea {
                min-height: 300px;
            }
        }
    }

    &__inline-button {
        --nebula-button-background-hover: transparent;
        link();
        border-bottom-color: inherit;
        border-radius: 0;
        display: inline;
        margin: 0;
        padding: 0;
        transform: translateY(-2px);
    }

    &__tag {
        line-height: 1rem;
    }
    &__tag-box {
        align-items: flex-start;
        background: $nebula-color-platform-white;
        border: 1px solid $nebula-color-platform-interface-500;
        border-radius: $nebula-border-radius-small;
        display: flex;
        flex-wrap: wrap;
        gap: $nebula-space-1x;
        padding: $nebula-space-1x $nebula-space-2x;
    }
    &__tag-input-group {
        align-self: flex-end;
        position: relative;
    }
    &__tag-input {
        --nebula-input-margin: 0 0 $nebula-space-1x;
        --nebula-input-padding: 0;
        --nebula-input-border-width: 0;
        --nebula-input-height: 1.25em;
        --nebula-input-border-radius: 0;
        --nebula-input-box-shadow: none;
        --nebula-input-font-size: 1em;
    }
    &__tag-menu {
        background: $nebula-color-platform-white;
        border-radius: $nebula-border-radius-small;
        box-shadow: $nebula-shadow-400;
        list-style-type: none;
        margin: 0;
        padding: $nebula-space-half;
        position: absolute;
        top: $nebula-space-5x;
        z-index: 1;
    }
    &__tag-menu-item {
        background: transparent;
        border: none;
        border-radius: $nebula-border-radius-small;
        min-width: 250px;
        padding: $nebula-space-1x;
        text-align: left;
        transition: $nebula-transition-default;
        width: 100%;
        &--disabled {
            opacity: .5;
            pointer-events: none;
        }
        &:hover {
            background: $nebula-color-platform-interactive-300
        }
    }
}
</style>
