<template>
    <img :src="DECareerConnectLockup">
</template>

<script>
const DECareerConnectLockup = require('@/assets/logos/de_career-connect_wordmark.svg');

export default {
    name: 'DECareerConnectLogo',
    data() {
        return {
            DECareerConnectLockup,
        };
    },
};
</script>
