<template>
   <PageWrapper padded contained class="educator-profile-setup" :class="{'educator-profile-setup--styled': flags['educator-setup-redesign']}">
        <NebulaGridRow>
            <NebulaGridBlock
                v-if="flags['educator-setup-redesign']"
                :columns="6"
                :offset="3"
                :mdSize="6"
                :mdOffset="1"
                :smSize="4"
            >
                <CompoundBlob>
                    <div class="educator-profile-setup__card">
                        <div class="educator-profile-setup__logo-wrapper">
                            <DECareerConnectLogo class="educator-profile-setup__logo" />
                        </div>
                        <ErrorBanner
                            v-if="this.showErrors && this.fieldsWithErrors.includes('privacyAndTermsChecked')"
                            header="Errors"
                            description="You must accept the terms and conditions and privacy policy to sign up for an account."
                            color="red"
                        />
                        <div class="educator-profile-setup__image-wrapper">
                            <img
                                :src="image"
                                class="educator-profile-setup__image"
                                :alt="$t('Teacher instructing')"
                            >
                        </div>
                        <div>
                            <h2 class="educator-profile-setup__welcome-header">{{ $t('welcome-header', {ns: 'educatorprofile'}) }}</h2>
                            <p class="educator-profile-setup__welcome-description">{{ $t('welcome-description', {ns: 'educatorprofile'}) }}</p>
                        </div>
                        <div class="educator-profile-setup__preview">
                            <DEProfileAvatar size="l" />
                            <div class="educator-profile-setup__preview-name">
                                <p>{{ displayName }}</p>
                                <p v-if="siteData.name">{{ siteData.name }}</p>
                                <p v-if="locationString">{{ locationString }}</p>
                            </div>
                        </div>
                        <div>
                            <p class="educator-profile-setup__terms-label">{{ $t('terms-and-conditions-label', { ns: 'termsconditions' }) }}</p>
                            <ProfileFormField
                                name="privacyAndTermsChecked"
                                inputType="check-box"
                                :inputGroupData="formData.privacyAndTermsChecked"
                                :validation="validateInput"
                                @input-updated="updateTermsAndPrivacy"
                            >
                                <template v-slot:checkbox-label>
                                    <span class="educator-profile-setup__checkbox-label">
                                        {{ $t('educator-privacy-terms-text', { ns: 'termsconditions' }) }}
                                    </span>
                                </template>
                            </ProfileFormField>
                        </div>
                        <ProfileSetupActions
                            :nextText="saveProcessing ? 'Processing' : $t('confirm-account', { ns: 'educatorprofile' })"
                            :nextDisabled="saveProcessing"
                            @click-next="handleSaveAndContinue"
                            :analyticsAttributes="{
                                next: {
                                    'data-click-type': 'Profile Setup Workflow: Educator',
                                    'data-click-name': $t('confirm-account', { ns: 'educatorprofile' }),
                                },
                            }"
                        />
                    </div>
                </CompoundBlob>
            </NebulaGridBlock>
            <NebulaGridBlock
                v-else
                :columns="6"
                :offset="3"
                :mdSize="6"
                :mdOffset="1"
                :smSize="4"
            >
                <Phase
                    class="employee-profile-setup__phase"
                    :data="{
                        header: 'Profile Setup',
                        description: 'Update your photo and profile details here.',
                    }"
                    :avatarProps="{
                        image: avatarImage
                    }"
                    @upload-modal-opened="handleUploadOpened"
                >
                    <ErrorBanner
                        v-if="this.showErrors && this.fieldsWithErrors.includes('termsChecked') || this.fieldsWithErrors.includes('privacyChecked')"
                        header="Errors"
                        description="You must accept the terms and conditions to sign up for an account."
                        color="red"
                    />
                    <ProfileFormSection header="Personal Details">
                        <ProfileFieldGroup horizontal>
                            <div class="educator-profile-setup__prepop-group">
                                <p class="educator-profile-setup__prepop-label">First Name</p>
                                <p class="educator-profile-setup__prepop-value">{{ data.firstName }}</p>
                            </div>
                            <div class="educator-profile-setup__prepop-group">
                                <p class="educator-profile-setup__prepop-label">Last Name</p>
                                <p class="educator-profile-setup__prepop-value">{{ data.lastName }}</p>
                            </div>
                        </ProfileFieldGroup>
                        <ProfileFieldGroup horizontal>
                            <ProfileFormField
                                name="preferredName"
                                :inputGroupData="formData.preferredName"
                                :validation="validateInput"
                                helperText="This will appear as your first name on your profile."
                            />
                            <ProfileFormField
                                name="pronoun"
                                :inputGroupData="formData.pronoun"
                                :validation="validateInput"
                                :selectOptions="pronounsOptions"
                            />
                        </ProfileFieldGroup>
                    </ProfileFormSection>
                    <ProfileFormSection header="Professional Details">
                        <div class="educator-profile-setup__prepop-group">
                            <p class="educator-profile-setup__prepop-label">School</p>
                            <p class="educator-profile-setup__prepop-value">{{ siteData.name }}</p>
                        </div>
                        <ProfileFieldGroup horizontal>
                            <div class="educator-profile-setup__prepop-group">
                                <p class="educator-profile-setup__prepop-label">City</p>
                                <p class="educator-profile-setup__prepop-value">{{ siteData.city }}</p>
                            </div>
                            <div class="educator-profile-setup__prepop-group">
                                <p class="educator-profile-setup__prepop-label">State</p>
                                <p class="educator-profile-setup__prepop-value">{{ siteData.state }}</p>
                            </div>
                        </ProfileFieldGroup>
                    </ProfileFormSection>
                    <ProfileFormSection v-if="!data.privacyChecked || !data.termsChecked">
                        <ProfileFieldGroup>
                            <p class="educator-profile-setup__terms-label">{{ $t('terms-and-conditions-label', { ns: 'termsconditions' }) }}</p>
                            <ProfileFormField
                                name="privacyChecked"
                                inputType="check-box"
                                :inputGroupData="formData.termsChecked"
                                :validation="validateInput"
                            >
                                <template v-slot:checkbox-label>
                                    <CheckboxLabel
                                        :text="$t('educator-terms-text', { ns: 'termsconditions' })"
                                        :link="$t('terms-of-use-link', { ns: 'termsconditions' })"
                                        :anchor="$t('terms-of-use-anchor', { ns: 'termsconditions' })"
                                    />
                                </template>
                            </ProfileFormField>
                            <ProfileFormField
                                name="privacyChecked"
                                inputType="check-box"
                                :inputGroupData="formData.privacyChecked"
                                :validation="validateInput"
                            >
                                <template v-slot:checkbox-label>
                                    <CheckboxLabel
                                        :text="$t('educator-privacy-text', { ns: 'termsconditions' })"
                                        :link="$t('privacy-policy-link', { ns: 'termsconditions' })"
                                        :anchor="$t('privacy-policy-anchor', { ns: 'termsconditions' })"
                                    />
                                </template>
                            </ProfileFormField>
                        </ProfileFieldGroup>
                    </ProfileFormSection>
                    <ProfileSetupActions
                        nextText="Save"
                        @click-next="handleSaveAndContinue"
                        :nextDisabled="saveProcessing"
                        :analyticsAttributes="{
                            next: {
                                'data-click-type': 'Profile Setup Workflow: Educator',
                                'data-click-name': 'Educator Details: Save & Continue',
                            },
                        }"
                    />
                </Phase>
            </NebulaGridBlock>
        </NebulaGridRow>
    </PageWrapper>
</template>

<script>
import { NebulaGrid } from '@discoveryedu/nebula-components';
import Phase from '@/components/shared/Profile/Phase.vue';
import ProfileFieldGroup from '@/components/shared/Profile/ProfileFieldGroup.vue';
import ProfileFormField from '@/components/shared/Profile/ProfileFormField.vue';
import ProfileFormSection from '@/components/shared/Profile/ProfileFormSection.vue';
import ProfileSetupActions from '@/components/shared/Profile/ProfileSetupActions.vue';
import CheckboxLabel from '@/components/shared/forms/CheckboxLabel.vue';
import ErrorBanner from '@/components/shared/ErrorBanner.vue';
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';
import CompoundBlob from '@/components/shared/brand/CompoundBlob.vue';
import DEProfileAvatar from '@/components/shared/Profile/DEProfileAvatar.vue';
import convertTextToLink from '@/utils/convertTextToLink';
import DECareerConnectLogo from '@/components/shared/tokens/DECareerConnectLogo.vue';

import { displayName } from '@/utils/data/display';

import createPayload from '@/mixins/data/createPayload';

import validation from '@/mixins/form/validation';
import {
    pronounsOptions,
} from '@/data/formData';

const image = require('@/assets/stock/teacher-instructing.png');

export default {
    name: 'EducatorProfileSetup',
    components: {
        CheckboxLabel,
        CompoundBlob,
        DEProfileAvatar,
        DECareerConnectLogo,
        ErrorBanner,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        PageWrapper,
        Phase,
        ProfileFormSection,
        ProfileFormField,
        ProfileFieldGroup,
        ProfileSetupActions,
    },
    mixins: [validation, createPayload],
    computed: {
        pronounsOptions,
        // feature flags
        flags() {
            return this.$store.getters.flags;
        },
        avatarImage() {
            const { avatarUrl } = this.$store.state.user.data;
            return avatarUrl;
        },
        data() {
            return this.$store.state.user.data;
        },
        displayName() {
            if (!this.data) {
                return null;
            }
            return displayName(this.data);
        },
        locationString() {
            if (!this.siteData) {
                return null;
            }

            let { state = '' } = this.siteData;
            const { city = '' } = this.siteData;

            if (state && city) {
                state = state ? `, ${state}` : '';
            }

            return `${city}${state}`;
        },
        siteData() {
            if (!this.data.site) {
                return {};
            }

            const { site_name: name = null, site_city: city = null, site_state: state = null } = this.data.site;

            return {
                name,
                city,
                state,
            };
        },
    },
    data() {
        return {
            showErrors: false,
            saveProcessing: false,
            image,
            formData: {
                preferredName: {
                    value: '',
                    display: 'Preferred Name',
                    required: false,
                    validation: {
                        maxDataLength: 50,
                        specialCharactersAllowed: true,
                    },
                    errorString: null,
                    error: null,
                    status: '',
                },
                pronoun: {
                    value: '',
                    display: 'Pronouns',
                    required: false,
                    validation: {
                        maxDataLength: 50,
                        specialCharactersAllowed: true,
                    },
                    errorString: null,
                    error: null,
                    status: '',
                },
                privacyChecked: {
                    value: false,
                    required: true,
                    errorString: null,
                    error: null,
                    status: '',
                },
                termsChecked: {
                    value: false,
                    required: true,
                    errorString: null,
                    error: null,
                    status: '',
                },
                privacyAndTermsChecked: {
                    value: false,
                    required: true,
                    errorString: null,
                    error: null,
                    status: '',
                },
            },
        };
    },
    methods: {
        async saveAction(storeable) {
            // this method is called in the handleSaveAndContinue method from the validation mixin
            let payload = await this.createPayload();
            let changed = false;

            const compareFields = ['pronoun', 'privacyChecked', 'termsChecked', 'preferredName'];

            compareFields.forEach((field) => {
                if (this.data[field] !== this.formData[field].value) {
                    changed = true;
                }
            });

            if (!this.data.isActive) {
                payload = await this.createPayload({ activate: true });
            }

            if (changed || !this.data.isActive) {
                this.saveProcessing = true;
                await this.$store.dispatch('updateUserData', storeable);
                await this.$store.dispatch('updateEducator', payload);
                this.saveProcessing = false;
            }

            this.$router.push('/');
        },
        updateTermsAndPrivacy(value) {
            const fields = ['privacyChecked', 'termsChecked'];

            fields.forEach((field) => {
                this.formData[field].value = value;
            });
        },
        async handleUploadOpened() {
            if (this.$store.state.user.data.notCreated) {
                const { email, id } = this.$store.state.user.data;
                const baseData = { email, id };
                const payload = await this.createPayload(baseData);
                await this.$store.dispatch('updateEducator', payload);
            }
        },
    },
    mounted() {
        this.populateFields(['pronoun'], null);

        if (this.flags['educator-setup-redesign']) {
            const { privacyChecked, termsChecked } = this.data;
            if (privacyChecked && termsChecked) {
                this.formData.privacyAndTermsChecked.value = termsChecked;
            }

            convertTextToLink(
                '.educator-profile-setup__checkbox-label',
                [{
                    text: 'Terms of Use',
                    className: 'educator-profile-setup__link',
                    newTab: true,
                    url: 'https://www.discoveryeducation.com/career-connect-terms-of-use',
                },
                {
                    text: 'Privacy Policy',
                    className: 'educator-profile-setup__link',
                    newTab: true,
                    url: 'http://www.discoveryeducation.com/career-connect-privacy-policy',
                }],
            );
        }

        if (this.flags['educator-setup-redesign']) {
            this.formData.privacyAndTermsChecked.required = true;
            this.formData.privacyChecked.required = false;
            this.formData.termsChecked.required = false;
        } else {
            this.formData.privacyAndTermsChecked.required = false;
            this.formData.privacyChecked.required = true;
            this.formData.termsChecked.required = true;
        }
    },
};
</script>

<style lang="stylus">
.educator-profile-setup {
    &__image-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            width: unset;
        }
    }

    &__image {
        height: 227px;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            height: 150px;
        }
        @media (min-width: $nebula-breakpoints-desktop) {
            height: 267px;
        }
    }

    &__prepop-label {
        prepop-label();
    }

    &__terms-label {
        prepop-label();
        margin-block-end: $nebula-space-2x;
        font-weight: 600px;
    }

    &__prepop-value {
        prepop-value();
    }
    &__prepop-group {
        flex: 1;
        .educator-profile-setup__prepop-label {
            margin-block-start: 0;
        }
    }

    &__logo {
        max-width: 232px;
    }

    &__logo-wrapper {
        display: flex;
        justify-content: center;
        margin-block-end: $nebula-space-2x;
    }

    &__link {
        link();
    }

    &__card {
        card-base();
        background: $nebula-color-white;
        padding: $nebula-space-3x;
    }
    &--styled {
        background: linear-gradient(82.82deg, #CFE6FA -0.05%, #FFFFFF 94.4%);
    }

    &__welcome-header {
        font-weight: 400;
        font-size: 28px;
        margin-bottom: $nebula-space-2x;
        margin-top: $nebula-space-2x;
    }

    &__welcome-description {
        font-weight: 400;
        font-size: 18px;
    }

    &__preview {
        display: flex;
        gap: $nebula-space-2x;
        margin-top: $nebula-space-2x;
        align-items: flex-start;
    }

    &__preview-name {
        p {
            margin: 0;
            font-size: $nebula-font-size-card-title;
            &:first-child {
                font-weight: 700;
            }
        }
    }
}
</style>
