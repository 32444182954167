const findAndCreateLink = (link, string) => {
    const {
        text,
        className,
        url,
        newTab,
    } = link;
    const linkResult = [];

    // create regex
    const escaped = text.replace('.', '[.]');
    const regex = new RegExp(`(${escaped})`, 'g');

    if (!string.match(regex)) {
        return [string];
    }

    const split = string.split(regex);

    split.forEach((str) => {
        const match = str.match(regex);
        let toAdd = str;

        if (match) {
            const a = document.createElement('a');

            if (className) {
                a.classList.add(className);
            }

            a.setAttribute('href', url);
            a.textContent = str;

            if (newTab) {
                a.setAttribute('target', '_blank');
            }

            toAdd = a;
        }

        linkResult.push(toAdd);
    });

    return linkResult;
};

const convertTextToLink = (selector, links) => {
    const element = document.querySelector(selector);
    const initialTextContent = element.textContent;

    let elements = [];

    if (!element) {
        return;
    }

    links.forEach((link) => {
        let linkResult = [];

        if (elements.length > 0) {
            let elementResult = [];
            // if elements have already been created, check any remaining strings for link matches
            elements.forEach((each) => {
                if (typeof each === 'string') {
                    const newElements = findAndCreateLink(link, each);
                    elementResult = elementResult.concat(newElements);
                } else {
                    // preserve existing links that have already been created
                    elementResult.push(each);
                }
            });
            linkResult = elementResult;
        } else {
            linkResult = findAndCreateLink(link, initialTextContent);
        }

        // store elements created to track they have been created
        elements = linkResult;
    });

    element.textContent = '';
    elements.forEach((eachElement) => element.append(eachElement));
};

export default convertTextToLink;
